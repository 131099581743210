exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-cont-tsx": () => import("./../../../src/pages/cont/[...].tsx" /* webpackChunkName: "component---src-pages-cont-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-legal-politica-de-confidentialitate-tsx": () => import("./../../../src/pages/legal/politica-de-confidentialitate.tsx" /* webpackChunkName: "component---src-pages-legal-politica-de-confidentialitate-tsx" */),
  "component---src-pages-legal-politica-privind-cookie-tsx": () => import("./../../../src/pages/legal/politica-privind-cookie.tsx" /* webpackChunkName: "component---src-pages-legal-politica-privind-cookie-tsx" */),
  "component---src-pages-legal-termeni-si-conditii-tsx": () => import("./../../../src/pages/legal/termeni-si-conditii.tsx" /* webpackChunkName: "component---src-pages-legal-termeni-si-conditii-tsx" */),
  "component---src-pages-login-index-tsx": () => import("./../../../src/pages/login/index.tsx" /* webpackChunkName: "component---src-pages-login-index-tsx" */),
  "component---src-pages-reset-pass-index-tsx": () => import("./../../../src/pages/resetPass/index.tsx" /* webpackChunkName: "component---src-pages-reset-pass-index-tsx" */),
  "component---src-pages-reset-pass-verification-index-tsx": () => import("./../../../src/pages/resetPassVerification/index.tsx" /* webpackChunkName: "component---src-pages-reset-pass-verification-index-tsx" */),
  "component---src-pages-signup-activate-account-tsx": () => import("./../../../src/pages/signup/activateAccount.tsx" /* webpackChunkName: "component---src-pages-signup-activate-account-tsx" */),
  "component---src-pages-signup-index-tsx": () => import("./../../../src/pages/signup/index.tsx" /* webpackChunkName: "component---src-pages-signup-index-tsx" */)
}

