module.exports = [{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://71f3e64a337c045667320a02e0a44dae@o236732.ingest.us.sentry.io/4506881323958272","tracesSampleRate":1,"replaysSessionSampleRate":0.1,"replaysOnErrorSampleRate":1},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Platformă clienți - GAZ NORD-EST","short_name":"GAZ NORD-EST","start_url":"/","background_color":"#FFFFFF","display":"standalone","icon":"src/images/faviconGNE.svg","crossOrigin":"use-credentials","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"58798926e9ec8ce8f2a71e8388b796c5"},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
