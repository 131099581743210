/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// require("dotenv").config({
//   path: `.env.${process.env.NODE_ENV}`,
// })

// You can delete this file if you're not using it
import "./src/components/globals/style.css"
import "./src/components/globals/auth.css"

if (process.env.GATSBY_IS_PRISMASERV)
  require("./src/components/globals/prismaColors.css")
else require("./src/components/globals/gneColors.css")
